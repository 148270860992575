<template>
  <div class="login form">


    <div class="step fade" v-if="step === 1">
      <form action="" @submit.prevent="login">
        <h3 class="anim center">Bienvenue</h3>
        <div class="info center anim">Connectez-vous avec votre adresse e-mail pour continuer</div>

        <label class="anim" for="email">Adresse E-mail</label>
        <el-input class="anim" id="email" autocomplete="on" type="email" v-model="email" name="email"></el-input>

        <label class="anim" for="password">Mot de passe</label>
        <el-input class="anim" id="password" autocomplete="on" type="password" v-model="password" show-password></el-input>
        <router-link tag="div" :to="{ name: 'user_mail_recovery_send' }" class="password_recup anim">Mot de passe oublié</router-link>

        <button class="anim">Connexion</button>

        <div class="pasencoreinscrit anim">
          <span>PAS ENCORE INSCRIT ?</span>
          <a @click.prevent="$emit('create')" href="">Créer mon compte</a>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import anime from 'animejs'
export default {
  data() {
    return {
      step: 1,

      email: null,
      password: null,

      fb_login: false,
      fb_data: {}
    }
  },
  name: "join",
  mounted() {
    // this.step_anim()

    // this.$nextTick(() => {
      let el = document.getElementsByClassName('fade')
      for(let n in el)
        el[n].classList.add('in');
    // })

  },
  watch: {
    step() {
      // this.step_anim()
    },
    getUser() {
      if(this.router) {
        if (localStorage.getItem("beforeLogin")) {
          let key = localStorage.getItem("beforeLogin")
          if (key === 'true') this.$router.go(-1)
          else this.$router.push({name: localStorage.getItem("beforeLogin")});
          localStorage.removeItem("beforeLogin");
        } else this.$router.push({name: "Home"});
      }
    }
  },
  computed: {
    getUser() {
      return this.$store.state.user.user
    }
  },
  props: [ 'router' ],
  methods: {
    login() {
      if (!this.email || !this.password) return

      this.$http.post('/user/auth', {email: this.email, password: this.password}).then((r) => {
        console.log(r)
        if (r.status === 200) {
          this.$notify({
            title: 'Bienvenue', offset: this.$notifyOffest, type: 'success',
          });
          // this.$gener_boutique_id(r.data.user)
          // this.$bo_boutique_id = localStorage.getItem('bo_boutique_id')
          this.$http.defaults.headers.common['Authorization'] = `Bearer ${ r.data.token }`


          this.$store.dispatch("user/login", r.data);
          this.$emit('success')
        }
      }).catch((e) => {
        this.$notify({
          title: e.response.data, offset: this.$notifyOffest, type: 'error',
        });
      })
    },
    step_anim() {
      anime({ targets: '.anim', opacity: 0, duration: 0 })
      setTimeout(function() {
        anime({
          targets: '.step .anim',
          translateX: ['-30px', 0],
          opacity: [0, 1],
          easing: 'easeOutCubic',
          duration: 400,
          delay: anime.stagger(20)
        })
      }, 0)
    }
  }
}
</script>

<style scoped>


.login .step { background: #fff; padding: 32px; box-shadow: 0 2px 4px var(--gris); border: 1px solid var(--gris) }

.password_recup { font-size: 12px; text-align: right; padding-top: 12px; cursor: pointer; }


/*.digits { margin-top: 30px; }*/
.anim { opacity: 1 }
.step .info { margin-bottom: 30px; font-size: 12px; margin-top: 14px; letter-spacing: 1.1px; line-height: 24px }

form { display: flex; flex-direction: column }

.button.fb {
  background: #1877f2;
  display: flex;
  justify-content: center; align-items: center;
  text-transform: none;
  font-size: 18px;
  font-weight: 500;
}

.button.fb img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.step { display: flex; flex-direction: column }

.step.fb { display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: calc((var(--vh, 1vh) * 100) - 60px); }
.step.fb img { width: 100%; height: 100%; background: #ccc; border-radius: 100%; border: none; position: relative; z-index: 2; }

.step.fb .img { position: relative;  width: 50vw; height: 50vw;  margin-bottom: 16px;}

.pasencoreinscrit { display: flex; align-items: center; flex-direction: column; font-size: 18px; padding-top: 20px; margin-top: 20px; border-top: 1px solid #eee; }
.pasencoreinscrit span { font-weight: bold; }
.pasencoreinscrit a { font-size: 16px; margin-top: 10px; }

.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80vw;
}
.load i {
  background: #af36e5;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 100%;
  animation: circle 1.2s linear infinite 0s;
}
.load i:nth-child(2) {
  animation-delay: 0.5s;
}
.load i:nth-child(3) {
  animation-delay: 1s;
}
button { margin-top: 30px; }

@keyframes circle {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  10% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}


</style>